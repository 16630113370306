<template>
    <div id="regexp" class="content">
        <div class="row">

            <div class="col col-lg-6 col-sm-12">

                <form>
                <div class="form-group row">
                    <label class="col-form-label col-lg-2">URL Regex</label>
                    <div class="col-lg-10">
                        <input id="url_regex" type="text" v-model="url" class="form-control" />

                    </div>
                </div>

                    <div class="form-group row" v-for="v in vars">
                        <label class="col-form-label col-lg-2">{{ v[0] }} - min</label>
                        <div class="col-lg-10">
                            <input :ref="v[0] + '_min'" type="text" class="form-control" :value="v[1]"/>

                        </div>

                        <label class="col-form-label col-lg-2">{{ v[0] }} - max</label>
                        <div class="col-lg-10">
                            <input :ref="v[0] + '_max'" type="text" class="form-control" :value="v[2]"/>

                        </div>
                    </div>
                </form>

                <button class="btn btn-primary" @click="run">Run</button>
            </div>

            <div class="col col-lg-6 col-sm-12">

                <p>{{ runningUrl }}</p>

                <pre> {{ currentResponse }}</pre>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from "axios";

    export default {
        name: "RegexCall",

        data ()
        {
            return {
                url: "",
                min: 0,
                max: 1,
                runningUrl: "",
                currentResponse: ""
            }
        },


        computed:
            {
                vars ()
                {
                    if (this.url === "")
                        return [];

                    let regexp = /{(.*?)}/gi;

                    let arrVars = [];
                    let match = regexp.exec(this.url);

                    while (match != null)
                    {
                        arrVars.push(match[1]);
                        match = regexp.exec(this.url);


                    }

                    arrVars = arrVars.map( v => v.split("|"));

                    return arrVars;
                }
            },

        methods:
            {
                async run ()
                {
                    let arrVars = [];

                    let regexp = /{(.*?)}/gi;
                    let match = regexp.exec(this.url);

                    if (match != null)
                    {
                        console.log(match);
                        let name = match[1].split("|")[0];

                        let ref = name + '_min';
                        let input = this.$refs[ref][0];
                        let min = parseInt(input.value);

                        ref = name + '_max';
                        input = this.$refs[ref][0];
                        let max = parseInt(input.value);

                        do
                        {
                            let url = this.url.replace(match[0], min);

                            this.runningUrl = url;

                            let response = await axios.get(this.runningUrl);


                            this.currentResponse = response.data;

                            min++;

                            console.log(min, max,);
                        } while (min < max);


                        match = regexp.exec(this.url);




                    }

                }
            }
    }
</script>

<style scoped>

</style>