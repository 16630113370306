<template>
    <div  class="square" :class="getStyle(index - 1)">
        <input
                type="text"
                v-model="letter"

                :ref="'letter' + (index -1)"
                maxlength="1"
                @keydown="checkMaxLength($event)"
        />
        <span v-if="gotLetter(index-1)" class="score_letter">{{ letterScore(index-1)}}</span>
    </div>
</template>

<script>

    import { mapState, mapGetters} from "vuex";

    export default {
        name: "BoardLetter",
        props:
            {
                index:Number,
                currentWordLettersList: Array,
                arrLettersList: [Array, Object],

            },

        computed:
            {
                ...mapState({
                    letterScoreList: ( { board: { letterScoreList }}) => letterScoreList,
                    current: ( { board: { current }}) => current,
                }),
                ...mapGetters(["currentBoard", "currentHolder", "boardCount"]),

                letter:
                    {
                        get ()
                        {
                            return this.getLetterAt(this.index - 1);
                        },

                        set (value)
                        {
                            this.onChangeLetter(value);
                        }
                    },


            },


        methods:
            {
                getLetterAt (index)
                {
                    if (this.currentBoard[index] == null || this.currentBoard[index] === "")
                        return "";

                    return this.currentBoard[index].substring(0,1);

                },

                getStyle (index)
                {

                    if(this.currentBoard[index] != null && this.currentBoard[index] !== "")
                    {
                        let strClass = "";

                        if (this.currentBoard[index].slice(-1) === "*")
                            strClass = "wildcard ";

                        if (this.currentWordLettersList[index] === 1)
                            strClass += "temp";
                        else
                            strClass += "used";

                        return strClass;
                    }

                    let iX = index % 15;
                    let iY = Math.floor(index / 15);


                    if ((iX == 0 || iX == 14) && (iY == 3 || iY == 11 ))
                    {
                        return "letter_double";
                    }

                    if (iY % 7 == 0)
                    {
                        if ((iX % 7 == 0) && (iY != 7 || iX != 7))
                        {
                            return "word_triple";
                        }
                        else if ((iY == 7 && iX == 7))
                        {
                            return "word_double word_start";
                        }
                        else if (iX > 0 && iX == 3 || iX == 11)
                            return "letter_double";
                    }

                    if ((iX == 2 || iX == 12) && (iY == 6 || iY == 8 ))
                    {
                        return "letter_double";
                    }




                    if ((iX == 6 || iX == 8) && (iY == 2 || iY == 6 || iY == 8 || iY == 12) )
                    {
                        return "letter_double";
                    }

                    if (iX == 7 && (iY == 3 || iY == 11))
                    {
                        return "letter_double";
                    }

                    if (iX % 4 == 1)
                    {

                        if ((iY == 5 || iY == 9) ||
                            (iX == 5 || iX == 9) && (iY == 1 || iY == 13))
                        {
                            return "letter_triple";
                        }
                    }

                    if ((iX == iY && ((iX > 0 && iX < 5) || (iX > 9 && iX < 15))) ||
                        ((14 - iX) == iY && ( (iX > 9 && iX < 15))) ||
                        ((14 - iX) == iY && ( (iX > 0 && iX < 5))))
                    {
                        return "word_double";
                    }
                },

                onChangeLetter(letter, wildcard = false)
                {


                    console.log("onChangeLetter", letter, wildcard);

                    //when we have a wildcard, we dont block for length
                    if (!wildcard && (letter.length > 1 || letter === undefined))
                    {
                        //this.arrLettersList[this.index - 1] = "";
                        this.$store.dispatch("letterBoard", {index: this.index - 1, letter: ""});
                        console.log("letter length is greater than 1", e);
                        return;
                    }

                    let letterMatch = letter.match(/([a-za\s\.])/);


                    if (letterMatch === null || (letter.length != 1 && !wildcard))
                    {

                        //this.arrLettersList[index - 1] = "";
                        this.$store.dispatch("letterBoard", {index: this.index - 1, letter: ""});
                        console.log("onKeyUp did not match", letter);
                        return;
                    }




                    console.log("onKeyUp  match", letter);
                    this.$store.dispatch("letterBoard", {index: this.index - 1, letter: letter});




                    return;


                },

                checkMaxLength (e)
                {
                    //.preventDefault();
                    console.log("checkMaxLength", e.key, this.letter);

                    if (this.letter.length === 1 && e.key === "*")
                    {
                        this.onChangeLetter(this.letter + "*", true);
                    }
                },

                gotLetter (index)
                {
                    return (this.currentBoard[index] != null && this.currentBoard[index] != "" && this.currentBoard[index].slice(-1) != "*");
                },

                letterScore (index)
                {
                    if (!(this.arrLettersList[index] != null && this.arrLettersList[index] != ""))
                    {
                        return;
                    }

                    let iScore = this.letterScoreList[this.arrLettersList[index]];

                    if (iScore < 10)
                        return " " + iScore;

                    return iScore;
                },
            },


        watch:
            {
                letter (newVal, oldVal)
                {
                    console.log("watch letter", newVal, oldVal);
                }
            }

    }
</script>

<style scoped>
    .temp
    {
        background-color: #7A6C50;

    }

    .temp input
    {
        color:#F3D79F;
    }

    .temp .score_letter
    {
        color:#F3D79F;
    }






    .letter_double
    {
        background-color: #4e8b98;
    }

    .letter_triple
    {
        background-color: #3f982b;
    }

    .word_double
    {
        background-color: #990000;
    }

    .word_triple
    {
        background-color: #985d34;
    }
</style>