var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "square", class: _vm.getStyle(_vm.index - 1) },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.letter,
            expression: "letter"
          }
        ],
        ref: "letter" + (_vm.index - 1),
        attrs: { type: "text", maxlength: "1" },
        domProps: { value: _vm.letter },
        on: {
          keydown: function($event) {
            return _vm.checkMaxLength($event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.letter = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _vm.gotLetter(_vm.index - 1)
        ? _c("span", { staticClass: "score_letter" }, [
            _vm._v(_vm._s(_vm.letterScore(_vm.index - 1)))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }