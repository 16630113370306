<template>

        <button
                type="button"
                class="btn btn-word ml-1 box-shadow"
                :class="classForBestScore"
                v-on="$listeners"
                data-popup="tooltip"
                title
                data-placement="bottom"

                @mouseover="$emit('mouseover',$event)"
                @mouseout="$emit('mouseout',$event)"
                @click="wordClick"
        ><span class="word-score">{{ score }}</span> {{ label }}<span class="badge badge-primary btn-nav" v-if="nav != ''">{{ nav }}</span></button>


</template>

<script>
    export default {
        name: "WordResult",
        props: ["word", "bestScore"],

        data ()
        {
            return {
                index: 0,
            }
        },

        computed:
            {
                label ()
                {
                    let strLabel = this.word.word;



                    return strLabel;
                },

                nav ()
                {
                    if (this.word.position != undefined && this.word.position.length > 1)
                    {
                        return `${this.word.position.length}`;
                    }

                    return "";
                },

                score ()
                {
                    if (this.word.position != undefined && this.word.position.length > 0)
                    {
                        return this.word.position[0].score;
                    }

                    return -1;
                },

                    classForBestScore ()
                    {
                            if (this.score === this.bestScore)
                                    return "best-score";
                    }
            },

        methods:
            {
                showPopover (e)
                {
                    if (this.popover != null)
                    {
                        t.popover(hide);
                    }

                    let t = $(e.target);
                    t.popover('show');

                    this.popover = t;
                },

                hidePopover (e)
                {
                    let t = $(e.target);
                    t.popover('hide');

                    this.popover = null;
                },

                wordClick ()
                {


                    this.$emit("wordClick", this.word, this.showNextPosition.bind(this));





                    console.log("after emit");
                },

                showNextPosition ()
                {
                    let p = this.word.position.shift();
                    this.word.position.push(p);
                }


            },


            mounted()
            {
            }
    }
</script>

<style scoped>

        .btn-word.best-score
        {
                background-color: #7A6C50;
                color:#FFFFFF;

        }

    .btn-word
    {
        background-color:#F3D79F;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    .btn-nav
    {
        margin-left: 8px;
        background-color:#7A6C50;
    }

    .pill-score
    {
        position: relative;

    }

    .word-score {
            font-size: 9px;
            color: #7A6C50;
    }





    .best-score .btn-nav {
            background-color: #F3D79F;
            color: #000000;
    }

    .best-score .word-score
    {
            color:#F3D79F;
    }


</style>