import Vue from "vue";

export default
{
    install (vue, opts)
    {


        Vue.prototype.$swal2 = function (obj, cl1 = null, cl2 = null)
        {
            swal(obj)
                .then(cl1, cl2);
        }
    }
}