import Vue from "vue";
import Vuex from "vuex"

import VueRouter from "vue-router";
import Sweetalert2 from "./vue/plugins/sweetalert2.js";
import storeBoard from "./storeBoard.js";

import axios from "axios";

import App     from "./vue/App.vue";


import SimpleSolver from "./vue/scrabble/SimpleSolver.vue";
import Board from "./vue/scrabble/Board.vue";
import RegexCall from "./vue/RegexCall.vue";


export default class Dictionnaries
{
    constructor ()
    {
        this.setupRoutes ();
        this.setupVue();
    }

    setupRoutes ()
    {
        this.routes = [

            {
                path: "/simple",
                components: { default: SimpleSolver  }
            },

            {
                path: "/",
                components: { default: Board  }
            },

            {
                path: "/regex-call",
                components: { default: RegexCall  }
            },
        ]   ;
    }
    
    setupVue ()
    {
        
        Vue.config.devtools = true;
        
        Vue.use(VueRouter);
        Vue.use(Vuex);
        Vue.use(Sweetalert2);




        const store = new Vuex.Store({
            modules:{
                board: storeBoard,

            },
        });

        store.subscribe ((mutation, state) =>
        {
            console.log("saving in localStorage");
            localStorage.setItem("state", JSON.stringify(state));
        });

        
        const router = new VueRouter({routes: this.routes, mode:"history"});
        const components =  { App };
        
        
        let vue = new Vue(
            {
                store,
                router,
                components,

            }

        ).$mount ("#container");

    }
}