var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content", attrs: { id: "scrabble" } }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col col-lg-4 col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header header-elements-sm-inline" }, [
            _vm.boardCount > 1
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.prev($event)
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-arrow-left7" })]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "pl-1 pr-1" }, [
                    _vm._v(
                      _vm._s(_vm.current.index) +
                        " de " +
                        _vm._s(_vm.boardCount)
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.next($event)
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-arrow-right7" })]
                  )
                ])
              : _c("div", [
                  _vm._v(
                    "\n                                     \n                                "
                  )
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "header-elements" }, [
              _vm.hasBoard
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger mr-2",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removeCurrent($event)
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-cross2" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.addNew($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "icon-grid7 mr-2" }),
                  _vm._v(" Nouvelle grille")
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "board" },
              _vm._l(15 * 15, function(i) {
                return _c("board-letter", {
                  key: "letter" + i,
                  attrs: {
                    index: i,
                    currentWordLettersList: _vm.currentWordLettersList,
                    arrLettersList: _vm.arrLettersList
                  }
                })
              }),
              1
            )
          ])
        ]),
        _vm._v(" "),
        _vm.hasBoard
          ? _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body header-elements-sm-inline" },
                [
                  _c("holder", { ref: "holder", attrs: { length: "7" } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "header-elements" }, [
                    _c(
                      "button",
                      {
                        ref: "btnReset",
                        staticClass: "btn btn-light ml-3",
                        attrs: { type: "button" },
                        on: { click: _vm.reset }
                      },
                      [
                        _c("i", { staticClass: "icon-undo mr-2" }),
                        _vm._v(" Réinitaliser vos lettres")
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-footer" }, [
                _c(
                  "button",
                  {
                    ref: "btnFind",
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.solve }
                  },
                  [
                    _vm._v(" Trouvez des mots avec vos lettres "),
                    _c("i", { staticClass: "ml-2", class: _vm.solveBtnIcon })
                  ]
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col col-lg-8 col-sm-12" },
        [
          _c("results", {
            attrs: { holder: _vm.$refs.holder, words: _vm.words },
            on: {
              wordClick: _vm.showWord,
              useCurrentWord: _vm.useCurrentWord,
              removeCurrentWord: _vm.removeCurrentWord
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }