var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.words.length == 0
      ? _c("div", { staticClass: "card" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _vm.words.length > 0
      ? _c(
          "div",
          { staticClass: "card" },
          [
            _c(
              "div",
              { staticClass: "card-header" },
              [
                _c("h4", [
                  _vm._v(
                    "Résultats avec les lettres suivantes - le " +
                      _vm._s(
                        _vm.lastDate.toLocaleDateString("fr-CA") +
                          " à " +
                          _vm.lastDate.toLocaleTimeString("fr-CA")
                      )
                  )
                ]),
                _vm._v(" "),
                _vm.holder != undefined
                  ? _c("holder", {
                      attrs: {
                        "start-letters": _vm.userLetters.join(""),
                        length: _vm.holder.letters.length
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "ul",
                { staticClass: "nav nav-tabs" },
                [
                  _vm.words.length > 0
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            ref: "tab-best-score",
                            staticClass: "nav-link",
                            attrs: {
                              "data-toggle": "tab",
                              href: "#tab-best-score"
                            },
                            on: {
                              click: function($event) {
                                return _vm.displayTab("tab-best-score")
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "tab-best-score mr-1" }, [
                              _vm._v(
                                "(" + _vm._s(_vm.bestScore[0].bestScore) + ")"
                              )
                            ]),
                            _vm._v(" Meilleurs "),
                            _c(
                              "span",
                              { staticClass: "badge badge-primary btn-nav" },
                              [_vm._v(_vm._s(_vm.bestScore.length))]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(15, function(i) {
                    return _vm.ofLength(i).length > 0
                      ? _c("li", { key: "gr" + i, staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              ref: "tab" + i,
                              refInFor: true,
                              staticClass: "nav-link",
                              attrs: {
                                "data-toggle": "tab",
                                href: "#tab-" + i + "-letters"
                              },
                              on: {
                                click: function($event) {
                                  _vm.displayTab("tab-" + i.toString())
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "tab-best-score mr-1" },
                                [_vm._v("(" + _vm._s(_vm.bestScoreOf(i)) + ")")]
                              ),
                              _vm._v(" " + _vm._s(i) + " lettres "),
                              _c(
                                "span",
                                { staticClass: "badge badge-primary btn-nav" },
                                [_vm._v(_vm._s(_vm.ofLength(i).length))]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content" },
                [
                  _vm._l(15, function(i) {
                    return _c(
                      "div",
                      {
                        key: "gr" + i,
                        staticClass: "tab-pane",
                        attrs: { id: "tab-" + i + "-letters" }
                      },
                      [
                        _vm.ofLength(i).length > 0 &&
                        _vm.shouldDisplay("tab-" + i.toString())
                          ? _c(
                              "div",
                              _vm._l(_vm.ofLength(i), function(word, index) {
                                return _c("word-result", {
                                  key: index + "-" + word.word,
                                  attrs: {
                                    word: word,
                                    bestScore: _vm.bestScoreOf(i)
                                  },
                                  on: { wordClick: _vm.wordClick }
                                })
                              }),
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane",
                      attrs: { id: "tab-best-score" }
                    },
                    [
                      _vm.shouldDisplay("tab-best-score")
                        ? _c(
                            "div",
                            _vm._l(_vm.bestScore, function(word, index) {
                              return _c("word-result", {
                                key: "best-" + index + "-" + word.word,
                                attrs: { word: word, bestScore: 100 },
                                on: { wordClick: _vm.wordClick }
                              })
                            }),
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.words.length) + " mot"),
                _vm.words.length > 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
                _vm._v(" trouvé"),
                _vm.words.length > 1 ? _c("span", [_vm._v("s")]) : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.currentWordList, function(word) {
              return _c("div", { staticClass: "card-footer" }, [
                _c("span", { staticClass: "btn btn-word box-shadow" }, [
                  _vm._v(_vm._s(word.word))
                ]),
                _vm._v(" : "),
                _c("span", { domProps: { innerHTML: _vm._s(word.definition) } })
              ])
            }),
            _vm._v(" "),
            _vm.currentWord !== null
              ? _c(
                  "div",
                  { staticClass: "card-footer header-elements-sm-inline" },
                  [
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.useWord($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "icon-checkmark3 mr-2" }),
                          _vm._v(" Utiliser ce mot")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-warning ml-2",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeWord($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "icon-cross2" }),
                          _vm._v(" Retirer ce mot de la grille")
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Comment utiliser cet outil?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }