import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

const storage = store => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.

        console.log("storage.subscribe", mutation);
    })
}

const state =
    {
        letterScoreList: {
            a: 1,
            b: 3,
            c: 3,
            d: 2,
            e: 1,
            f: 4,
            g: 2,
            h: 4,
            i: 1,
            j: 8,
            k: 10,
            l: 1,
            m: 2,
            n: 1,
            o: 1,
            p: 3,
            q: 8,
            r: 1,
            s: 1,
            t: 1,
            u: 1,
            v: 4,
            w: 10,
            x: 10,
            y: 10,
            z: 10
        },


        current:
            {
                name: "",
                index: 0
            },

        boards:
            {
            },


        simpleSolver:
            {
                board: "",
                holder: "",
                length: 15
            },


        mode: ""


    };

const getters =
    {

        boardCount (state)
        {
            return Object.keys(state.boards).length;
        },

        currentHolder (state)
        {
            if (state.mode == "simple")
            {
                return state.simpleSolver.holder;
            }
            else if (state.mode == "full")
            {
                if (state.current.name == "")
                    return "";

                if (state.boards[state.current.name] === undefined)
                    return "";

                return state.boards[state.current.name].holder;
            }

            return "";
        },

        currentBoard (state)
        {
            if (state.current.name == "")
                return [];

            if (state.boards[state.current.name] === undefined)
                return [];

            return state.boards[state.current.name].board;
        },


    
    };

const actions =
    {


        letterList ({commit, getters}, payload)
        {
            commit("letterList", {payload, getters} );
        },

        letterBoard ({commit, getters}, payload)
        {
            commit("letterBoard", payload );
        },


        simpleSolverLength ({commit, getters}, payload)
        {
            commit("simpleSolverLength", payload );
        },

        simpleSolverHolder ({commit, getters}, payload)
        {
            commit("simpleSolverHolder", payload );
        },

        simpleSolverBoard ({commit, getters}, payload)
        {
            commit("simpleSolverBoard", payload );
        },
    };
    
const mutations =
    {

        addNewBoard (state, payload)
        {
            let strName = "board" + (new Date()).getTime();

            Vue.set(state.boards, strName, { holder: "", board: []});
            Vue.set(state.current, "name", strName);
            Vue.set(state.current, "index", payload.index);

        },

        prevBoard (state)
        {

        },


        nextBoard (state)
        {
            let first = "";
            let bIsNext = false;

            for(let strKey in state.boards)
            {
                if (bIsNext)
                {
                    Vue.set(state.current, "name", strKey);
                    Vue.set(state.current, "index", state.current.index++);
                    return;
                }

                if (first == "")
                    first = strKey;

                if (strKey == state.current.name)
                    bIsNext = true;
            }

            //we have reach the end, so next is first
            if (bIsNext)
            {
                Vue.set(state.current, "name", first);
                Vue.set(state.current, "index", 1);
            }

        },


        removeCurrent (state)
        {
            let strName = state.current.name;

            Vue.delete(state.boards, strName, { holder: "", board: []});


            let arrKeys = Object.keys(state.boards);

            if (arrKeys.length > 0)
            {
                Vue.set(state.current, "name", arrKeys[0]);
                Vue.set(state.current, "index", 1);
            }
            else
            {
                Vue.set(state.current, "name", "");
                Vue.set(state.current, "index", 0);
            }

        },



        currentBoardName (state, payload)
        {
            state.current.name = payload;

        },



        letterList (state, {payload,getters})
        {
            let arrLetters = getters.currentHolder.split("");

            arrLetters[payload.index] = payload.letter;
            state.boards[state.current.name].holder = arrLetters.join("");

        },

        letterBoard (state, payload)
        {
            //state.boards[state.current.name].board[payload.index] = payload.letter;

            Vue.set(state.boards[state.current.name].board, payload.index, payload.letter)

        },

        simpleSolverLength (state, payload)
        {
            console.log("store simpleSolverLength");
        },

        simpleSolverHolder (state, payload)
        {
            console.log("store simpleSolverHolder");
        },

        simpleSolverBoard (state, payload)
        {
            console.log("store simpleSolverBoard");
        },

        mounted (state, payload)
        {
            state.boards = payload.boards;
            state.current = payload.current;
        },

        mode (state, payload)
        {
            state.mode = payload;
        }

    };


let storeBoard =
    {
        state,
        getters,
        mutations,
        actions,
        plugins: [ storage ]
    }
;



export default storeBoard;

