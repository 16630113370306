<template>

    <div id="scrabble" class="content">
        <div class="row">
            <div class="col col-lg-4 col-sm-12">

                            <div class="card">
                                <div class="card-header header-elements-sm-inline">
                                    <div v-if="boardCount > 1">
                                        <button class="btn btn-primary" @click.prevent="prev"> <i class="icon-arrow-left7"></i></button>
                                            <span class="pl-1 pr-1">{{ current.index }} de {{ boardCount}}</span>
                                        <button class="btn btn-primary" @click.prevent="next"><i class="icon-arrow-right7"></i></button>

                                    </div>

                                    <div v-else>
                                        &nbsp;
                                    </div>

                                    <div class="header-elements">
                                        <button class="btn btn-danger mr-2" @click.prevent="removeCurrent" v-if="hasBoard"> <i class="icon-cross2"></i></button>
                                        <button class="btn btn-primary" @click.prevent="addNew" > <i class="icon-grid7 mr-2"></i> Nouvelle grille</button>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="board">
                                        <board-letter
                                                v-for="i in (15*15)"
                                                :index="i"
                                                :key="'letter' + i"
                                                :currentWordLettersList="currentWordLettersList"
                                                :arrLettersList="arrLettersList"
                                        ></board-letter>
                                    </div>
                                </div>






                            </div>

                <div class="card" v-if="hasBoard">

                    <div class="card-body header-elements-sm-inline">
                        <holder
                                ref="holder"
                                length="7">

                        </holder>

                        <div class="header-elements">
                            <button type="button"  class="btn btn-light ml-3" ref="btnReset" @click="reset"><i class="icon-undo mr-2"></i> Réinitaliser vos lettres</button>
                        </div>
                    </div>

                    <div class="card-footer">
                        <button type="button"  class="btn btn-primary" ref="btnFind" @click="solve"> Trouvez des mots avec vos lettres <i class="ml-2" :class="solveBtnIcon"></i> </button>
                    </div>
                </div>









            </div>

            <div class="col col-lg-8 col-sm-12">


                <results
                        :holder="$refs.holder"
                        :words="words"
                        @wordClick="showWord"
                        @useCurrentWord="useCurrentWord"
                        @removeCurrentWord="removeCurrentWord"
                ></results>



            </div>

        </div>

        <div class="row">



        </div>
    </div>


</template>

<script>
    import Holder from "./Holder.vue";
    import Results from "./Results.vue";
    import axios from "axios";
    import Vue from "vue";
    import { mapState, mapGetters} from "vuex";
    import BoardLetter from "./BoardLetter.vue";

    export default {
        name: "Board",
        components: {BoardLetter, Results, Holder},

        data ()
        {
            return {
                arrLettersList: {},
                arrHolder: {},
                words: [],
                currentWord: null,
                currentWordPos: [],
                currentWordLettersList: [],
                mode: "waiting"

            }
        },


        computed:
            {
                ...mapState({
                    letterScoreList: ( { board: { letterScoreList }}) => letterScoreList,
                    current: ( { board: { current }}) => current,
                }),
                ...mapGetters(["currentBoard", "currentHolder", "boardCount"]),

                solveBtnIcon ()
                {
                    if (this.mode === "waiting")
                    {
                        return "icon-checkmark3";
                    }
                    else if (this.mode === "solving")
                    {
                        return "icon-spinner spinner";
                    }

                    return "";
                },


                hasBoard ()
                {
                    return this.boardCount > 0;
                },


                holder ()
                {
                    return this.$refs.holder;
                },







            },

        methods:
            {
                addNew ()
                {
                    this.removeWord();
                    this.$store.commit("addNewBoard", { index: this.boardCount + 1});
                },

                next ()
                {
                    this.removeWord();
                    this.$store.commit("nextBoard");

                },


                prev ()
                {
                    this.removeWord();
                    this.$store.commit("prevBoard");

                },

                init ()
                {

                    if (this.hasBoard)
                    {
                        this.holder.init(this.currentHolder);
                        this.arrLettersList = this.currentBoard;
                    }

                },







                removeCurrent ()
                {
                    this.$swal2(
                    {
                        title: 'Voulez-vous vraiment effacer cette grille?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Oui',
                        cancelButtonText: 'Annuler',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonClass: 'btn btn-danger',
                        buttonsStyling: false
                    }, this.doRemoveCurrent.bind(this), null);
                },

                doRemoveCurrent ()
                {
                    this.arrLettersList = [];
                    this.$store.commit("removeCurrent")
                },


                async solve ()
                {
                    this.words = [];

                    this.removeWord(this.currentWord);


                    let letters = this.currentBoard;
                    let holder = this.$refs.holder.letters.join("").toLowerCase();


                    for (let i = letters.length; i < (15 * 15 -1); i++)
                    {
                        letters[i] = "";
                    }


                    this.arrHolder[this.currentBoard] = holder;

                    this.mode = "solving";
                    let response = await axios.post("/api/fr/solve", { letters, holder });
                    this.mode = "waiting";

                    this.words = response.data;
                    this.lastDate = new Date();


                },

                reset ()
                {
                    this.holder.init("");

                    this.save();

                },

                showWord (word)
                {

                    this.removeWord(this.currentWord);

                    let pos = word.position[0].position;
                    this.currentWordPos = pos;


                    console.log("showWord", word.word);
                    for (let i = 0; i < pos.length; i++)
                    {
                        let obj = pos[i];


                        if (!obj.onBoard)
                            this.currentWordLettersList[obj.index] = 1;


                        if (!obj.onBoard)
                        {
                            this.$store.dispatch("letterBoard", {index: obj.index, letter: obj.letter + (obj.wildcard ? "*" : "")});
                        }
                    }

                    this.currentWord = word;

                },

                removeWord ()
                {
                    this.currentWordLettersList = [];

                    if (this.currentWord === null)
                    {
                        this.currentWord = null;
                        this.currentWordPos = [];
                        return;
                    }


                    let word = this.currentWord;

                    let pos = this.currentWordPos;

                    for (let i = 0; i < pos.length; i++)
                    {
                        let obj = pos[i];

                        console.log("removeWord", i, pos, obj);
                        if (!obj.onBoard)
                        {
                            console.log("remove letter from board", obj.letter);
                            this.$store.dispatch("letterBoard", {index: obj.index, letter: ""});
                        }
                        //this.lettersList[obj.index] = obj.letter;
                    }

                    this.currentWord = null;
                    this.currentWordPos = [];



                },






                useCurrentWord ()
                {

                    let pos = [...this.currentWordPos];

                    this.removeWord(this.currentWord);

                    ;
                    for (let i = 0; i < pos.length; i++)
                    {
                        let letter = pos[i];

                        console.log(i, letter);
                        //if we have a "letter" between A and Z
                        if (letter !== undefined )
                        {
                            let strLetter = letter.letter;

                            if (strLetter.length === 1 && strLetter.match(/([a-z])/) !== null)
                            {
                                console.log("dispatch letterBoard", letter.index, strLetter, letter);
                                this.$store.dispatch("letterBoard", {index: letter.index, letter: strLetter});
                            }
                        }
                    }



                },

                removeCurrentWord ()
                {


                    for (let i = 0; i < this.currentWordPos.length; i++)
                    {
                        let letter = this.currentWordPos[i];

                        //if we have a "letter" between A and Z
                        if (letter !== undefined && letter.letter.length === 1 && letter.letter.match(/([a-z])/) !== null )
                        {
                            if (!letter.onBoard)
                            {
                                this.$store.dispatch("letterBoard", {index: letter.index, letter: ""});
                            }
                        }
                    }


                    this.removeWord(this.currentWord);
                }
            },

        watch:
            {
                currentBoard ()
                {
                    this.init();
                }
            },

        mounted ()
        {
            this.$store.commit("mode", "full");
            this.init();


        }
    }
</script>

<style scoped>


    .board
    {
        display:grid;
        grid-template-columns:  repeat(15, 36px);
        grid-template-rows:     repeat(15, 36px);
    }


</style>