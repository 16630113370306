var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "holder", style: _vm.getGridStyle() },
    _vm._l(_vm.holderLength, function(i) {
      return _c(
        "div",
        {
          key: "letter" + i,
          staticClass: "square",
          class: _vm.getClass(i - 1)
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localLetters[i - 1],
                expression: "localLetters[i - 1]"
              }
            ],
            ref: "letter" + (i - 1),
            refInFor: true,
            attrs: { type: "text", maxlength: "1" },
            domProps: { value: _vm.localLetters[i - 1] },
            on: {
              keyup: function($event) {
                return _vm.goNextOnFull(i, $event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.localLetters, i - 1, $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.gotLetter(i - 1)
            ? _c("span", { staticClass: "score_letter" }, [
                _vm._v(_vm._s(_vm.letterScore(i - 1)))
              ])
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }