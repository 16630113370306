var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    _vm._g(
      {
        staticClass: "btn btn-word ml-1 box-shadow",
        class: _vm.classForBestScore,
        attrs: {
          type: "button",
          "data-popup": "tooltip",
          title: "",
          "data-placement": "bottom"
        },
        on: {
          mouseover: function($event) {
            return _vm.$emit("mouseover", $event)
          },
          mouseout: function($event) {
            return _vm.$emit("mouseout", $event)
          },
          click: _vm.wordClick
        }
      },
      _vm.$listeners
    ),
    [
      _c("span", { staticClass: "word-score" }, [_vm._v(_vm._s(_vm.score))]),
      _vm._v(" " + _vm._s(_vm.label)),
      _vm.nav != ""
        ? _c("span", { staticClass: "badge badge-primary btn-nav" }, [
            _vm._v(_vm._s(_vm.nav))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }