<template>


    <div id="container" style="display:contents"><!-- this node will be removed on mount -->

        <router-view></router-view>
    </div>

</template>

<script>

    import Vue   from "vue";
    import axios from "axios";

    import _ from 'lodash';
    import SimpleSolver from "./scrabble/SimpleSolver.vue";

    Object.defineProperty(Vue.prototype, '$_', { value: _ });

    export default {
        name: "app",
        components: {SimpleSolver},


        created ()
        {
            let state = JSON.parse(localStorage.getItem("state"));

            console.log(state);
            if (state !== undefined && state !== null)
                this.$store.commit("mounted", state.board);

        }
    }
</script>

<style>

    .square
    {
        width:32px;
        background-color:#DFF8FC;
        margin:2px;
        border-radius: 10%;
        box-shadow: inset -1px 18px 5px -14px rgba(0,0,0,0.1);

    }

    .square.wildcard
    {
        box-shadow: none;
        background-color: rgba(243, 215, 159, 0.5);
    }

    .box-shadow
    {
        box-shadow: inset -1px 18px 5px -14px rgba(0,0,0,0.1);
    }

    .square > input[type='text']
    {
        width:32px;
        height:32px;
        font-size: 12pt;
        text-align:center;
        background-color: transparent;
        border:none;
        text-transform: uppercase;
    }


    .used
    {
        background-color: #F3D79F;

    }

    .used input
    {
        color:#000000;
    }

    .wildcard.used input
    {
        color:#222222;
    }


    .score_letter
    {
        position:relative;
        left:20px;
        top:-13px;
        font-size:9px;
        width:11px;
        display:block;
        text-align:right;
    }
</style>