<template>
    <div class="holder" :style="getGridStyle()">

        <div  v-for="i in holderLength" class="square" :class="getClass(i - 1)" :key="'letter' + i">
            <input
                    type="text"
                    v-model="localLetters[i - 1]"
                    :ref="'letter' + (i -1)"
                    maxlength="1"


                    @keyup="goNextOnFull(i,$event)"
            />
            <span v-if="gotLetter(i-1)" class="score_letter">{{ letterScore(i-1)}}</span>
        </div>


    </div>
</template>

<script>

    import Vue from "vue";
    import { mapState, mapGetters } from "vuex";

    export default {
        name: "Holder",
        props:
            {
                length: [Number, String],
                whitespace: Boolean,
                replaceChar: String,
                startLetters: String,
                storeCommit:
                    {
                        type:String,
                        default: "letterList"
                    }},

        data ()
        {
            return {
                localLetters: ["", "", "", "", "", "", ""]
            }
        },

        computed:
            {
                ...mapState({
                    letterScoreList: ( { board: { letterScoreList }}) => letterScoreList
                }),

                ...mapGetters(["currentHolder"]),

                lettersList:
                {
                    get ()
                    {
                        let h = this.currentHolder;

                        return this.currentHolder.split("");
                    },

                    set (a)
                    {
                       console.trace("leterList set");
                    }
                },

                letters ()
                {



                    let arrWithWildcard = [];

                    for (let i = 0; i < this.length; i++)
                    {
                        let l = this.localLetters[i];

                        if  (l === "" || l === undefined)
                        {

                            l = this.whitespace ? " " : "";
                        }


                        arrWithWildcard[i] = l;
                    }

                    return arrWithWildcard;
                },


                initialLetters ()
                {
                    if (this.startLetters == undefined)
                        return [];

                    return this.startLetters;
                },


                holderLength ()
                {
                    return parseInt(this.length);
                }
            },

        methods:
            {
                init (letters)
                {
                    this.localLetters = letters.split("");
                },

                onChanged (e)
                {
                  console.log("onChanged", e);
                },

                getClass (index)
                {

                    if (this.gotLetter(index))
                        return "used";

                    return "";
                },

                getGridStyle ()
                {
                  return `grid-template-columns: repeat(${this.holderLength}, 36px);`;
                },


                gotLetter (index)
                {
                    return (this.localLetters[index] != null && this.localLetters[index] != "");
                },

                letterScore (index)
                {
                    if (!(this.localLetters[index] != null && this.localLetters[index] != ""))
                    {
                        return;
                    }


                    let iScore = this.letterScoreList[this.localLetters[index]];

                    if (iScore < 10)
                        return " " + iScore;

                    return iScore;
                },

                commitChange (index)
                {
                    let letter = this.localLetters[index - 1];

                    console.log("holder - commitChange", letter, index);

                    if (letter == undefined || letter.match(/([a-z\s\.\*])/) === null)
                    {
                        this.$store.dispatch(this.storeCommit, {index: index - 1, letter: ""});
                    }
                    else if (letter != undefined)
                    {
                        this.$store.dispatch(this.storeCommit, {index: index - 1, letter: letter});
                    }
                },

                goNextOnFull(index, e)
                {
                    this.commitChange(index);
                    return;
                    e.preventDefault();




                    let letter = this.localLetters[index - 1];

                    console.log("goNextOnFull", letter, index - 1, this.localLetters);

                    if (letter.match(/([a-z\s\.])/) === null)
                    {

                        this.localLetters[index - 1] = "";
                        this.$store.dispatch(this.storeCommit, {index: index - 1, letter: ""});

                        return;
                    }

                    if (letter != undefined && letter.length > 0)
                    {

                        this.$store.dispatch(this.storeCommit, {index: index - 1, letter: letter});

                        if ((e.shiftKey && e.code == "Tab") || (e.key == "Shift"))
                            return;

                        if (index < this.length)
                        {
                            this.$refs["letter" + (index)][0].focus();
                        }
                        else
                        {

                            this.$emit("complete");
                        }
                    }
                    else
                    {

                    }
                },

                reset ()
                {
                    this.localLetters = [];
                }
            },

        mounted()
            {
                this.reset();
                this.localLetters = this.startLetters != undefined ? this.startLetters.split("") : [];
            }
    }
</script>

<style scoped>

    .holder {
        display: grid;

        grid-template-rows: 36px;
        grid-column-gap: 0px;
        grid-row-gap: 0px;

    }


    input[type='text']
    {
        width:32px;
        height:32px;
        font-size: 11pt;
        text-align:center;
    }
</style>