<template>
    <div class="content">
        <div class="row">
            <div class="col md-3">

                <div class="content">
                    <div class="row">
                        <div class="col">
                            <label for="boardLength">Longueur max : {{ boardLength}}</label>
                            <input id="boardLength" type="range" min=2 max=15 value=7 v-model="boardLength" />
                            <holder
                                    :length="boardLength"
                                    :whitespace=true
                                    replaceChar="."
                                    ref="board"
                                    storeCommit="simpleSolverBoard"
                                    @complete="holderComplete"></holder>



                            <holder
                                    class="mt-2"
                                    :length=7
                                    :whitespace=false
                                    ref="holder"
                                    storeCommit="simpleSolverHolder"
                                    @complete="holderComplete"></holder>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">

                            <button type="button"  class="btn btn-primary" ref="btnFind" @click="find"><i class="icon-checkmark3 mr-2"></i> Find</button>
                            <button type="button"  class="btn btn-light ml-3" ref="btnFind" @click="reset"><i class="icon-undo mr-2"></i> Reset</button>

                        </div>
                    </div>
                </div>

            </div>



        </div>

        <div class="row">

                <div class="col">


                    <results
                        :holder="holder"
                        :words="words"
                        @wordClick="showWord"
                    ></results>



                </div>

        </div>
    </div>
</template>

<script>
    import Holder from "./Holder.vue";
    import axios from "axios";
    import Results from "./Results.vue";

    export default {
        name: "SimpleSolver",

        components:  {Results, Holder  },
        data ()
        {
            return {

                words: [],
                boardLength: 7,

            }
        },

        computed:
            {
                holder ()
                {
                    return this.$refs.holder;
                }
            },

        methods:
            {

                async find ()
                {
                    this.words = [];

                    let letters = this.$refs.holder.letters.join("").toLowerCase();
                    let board = this.$refs.board.letters.join("").toLowerCase();


                    console.log("find", letters, board);
                    console.log(this.$refs.holder);


                    let response = await axios.post("/api/fr/find", { letters, board });

                    this.words = response.data;
                    this.lastDate = new Date();
                },

                reset ()
                {
                    this.$refs.holder.reset();
                    this.$refs.board.reset();
                },


                holderComplete()
                {
                    console.log("holderComplete");
                    this.$refs.btnFind.focus();
                },



                sleep(ms)
                {
                    return new Promise(resolve => setTimeout(resolve, ms));
                },

                showWord (word)
                {
                    console.log("showWord", word);
                }



            },

        async mounted ()
        {
            this.$store.commit("mode", "simple");

        }
    }
</script>

<style scoped>

    h3
    {
        font-weight: bold;
    }
    .word
    {
        padding-right: 5px;
    }


</style>