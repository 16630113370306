var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col md-3" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("label", { attrs: { for: "boardLength" } }, [
                  _vm._v("Longueur max : " + _vm._s(_vm.boardLength))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.boardLength,
                      expression: "boardLength"
                    }
                  ],
                  attrs: {
                    id: "boardLength",
                    type: "range",
                    min: "2",
                    max: "15",
                    value: "7"
                  },
                  domProps: { value: _vm.boardLength },
                  on: {
                    __r: function($event) {
                      _vm.boardLength = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("holder", {
                  ref: "board",
                  attrs: {
                    length: _vm.boardLength,
                    whitespace: true,
                    replaceChar: ".",
                    storeCommit: "simpleSolverBoard"
                  },
                  on: { complete: _vm.holderComplete }
                }),
                _vm._v(" "),
                _c("holder", {
                  ref: "holder",
                  staticClass: "mt-2",
                  attrs: {
                    length: 7,
                    whitespace: false,
                    storeCommit: "simpleSolverHolder"
                  },
                  on: { complete: _vm.holderComplete }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "button",
                {
                  ref: "btnFind",
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.find }
                },
                [
                  _c("i", { staticClass: "icon-checkmark3 mr-2" }),
                  _vm._v(" Find")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  ref: "btnFind",
                  staticClass: "btn btn-light ml-3",
                  attrs: { type: "button" },
                  on: { click: _vm.reset }
                },
                [_c("i", { staticClass: "icon-undo mr-2" }), _vm._v(" Reset")]
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("results", {
            attrs: { holder: _vm.holder, words: _vm.words },
            on: { wordClick: _vm.showWord }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }