<template>
    <div>
        <div class="card" v-if="words.length == 0">
            <div class="card-header">
                <h1>Comment utiliser cet outil?</h1>
            </div>
        </div>
        <div class="card" v-if="words.length > 0">

            <div class="card-header">

                <h4>Résultats avec les lettres suivantes - le {{ lastDate.toLocaleDateString('fr-CA') + ' à ' + lastDate.toLocaleTimeString('fr-CA') }}</h4>

                <holder
                        v-if="holder != undefined"
                        :start-letters="userLetters.join('')"

                        :length="holder.letters.length"></holder>


            </div>

            <div class="card-body">

                <!--<div class="row">-->
                    <!--<holder-->
                        <!--ref="holderFilter"-->
                        <!--:whitespace=true-->
                        <!--:length=15></holder>-->


                    <!--<button class="btn btn-primary" @click="filterResults">Filtrer</button>-->
                <!--</div>-->
                <ul class="nav nav-tabs">

                    <li
                            v-if="words.length > 0"
                            class="nav-item">
                        <a
                                class="nav-link"
                                data-toggle="tab"
                                ref="tab-best-score"
                                href="#tab-best-score"
                                @click="displayTab('tab-best-score')">
                            <span class="tab-best-score mr-1">({{ bestScore[0].bestScore}})</span> Meilleurs <span class="badge badge-primary btn-nav">{{ bestScore.length }}</span>
                        </a>

                    </li>


                    <li
                            class="nav-item"
                            v-for="i in 15"
                            :key="'gr' + i" v-if="ofLength(i).length > 0">
                        <a
                                class="nav-link"
                                data-toggle="tab"
                                :ref="'tab' + i"
                                :href="'#tab-' +  i + '-letters'"
                                @click="displayTab('tab-' + i.toString())"><span class="tab-best-score mr-1">({{ bestScoreOf(i) }})</span> {{i}} lettres <span class="badge badge-primary btn-nav">{{ ofLength(i).length }}</span></a>
                    </li>

                </ul>


                <div class="tab-content">
                    <div
                            class="tab-pane"
                            v-for="i in 15"
                            :key="'gr' + i"
                            :id="'tab-' + i + '-letters'">

                            <div v-if="ofLength(i).length > 0 && shouldDisplay('tab-' + i.toString())">
                                <word-result
                                        v-for="(word,index) in ofLength(i)"
                                        :word="word"
                                        :key="index + '-' + word.word"
                                        :bestScore="bestScoreOf(i)"
                                        @wordClick="wordClick"></word-result>
                            </div>


                    </div>

                    <div
                            class="tab-pane"
                            id="tab-best-score">

                            <div v-if="shouldDisplay('tab-best-score')">
                                <word-result
                                        v-for="(word,index) in bestScore"
                                        :word="word"
                                        :key="'best-' + index + '-' + word.word"
                                        :bestScore="100"
                                        @wordClick="wordClick"></word-result>
                            </div>
                    </div>
                </div>

            </div>

            <div class="card-footer" >

                <span>{{ words.length }} mot<span v-if="words.length > 1">s</span> trouvé<span v-if="words.length > 1">s</span></span>

            </div>

            <div class="card-footer"  v-for="word in currentWordList" >
                <span class="btn btn-word box-shadow">{{ word.word}}</span> : <span v-html="word.definition"></span></div>



            <div class="card-footer header-elements-sm-inline" v-if="currentWord !== null">

                <div>
                    <button class="btn btn-primary" @click.prevent="useWord" > <i class="icon-checkmark3 mr-2"></i> Utiliser ce mot</button>
                    <button class="btn btn-warning ml-2" @click.prevent="removeWord"> <i class="icon-cross2"></i> Retirer ce mot de la grille</button>
                </div>

            </div>




        </div>
    </div>
</template>

<script>
    import WordResult from "./WordResult.vue";
    import Holder from "./Holder.vue";

    import Vue from "vue";

    export default {
        name: "Results",
        components: {WordResult, Holder},
        props: ["words", "holder"],

        data ()
        {
            return {
                bTabSelected: false,
                popover: null,
                lastDate: new Date(),
                arrShowWordOfLength: [],
                currentDefinition: "",
                currentWord: null,
                filter: "",
                currentTab: ""
            }
        },

        computed:
            {
                firstTabIndex ()
                {
                    for (let i = 2; i < 15; i++)
                    {
                        if (this.ofLength(i).length > 0)

                            return i;
                    }

                    return 0;
                },

                currentWordList ()
                {

                    if (this.currentWord === undefined || this.currentWord === null)
                        return [];

                    //let wordsList = [this.currentWord];


                    //return [this.currentWord];
                    if (typeof this.currentWord.position[0].near != "Array" && typeof this.currentWord.position[0].near != "object")
                    {
                        console.error("near is not n array",  typeof this.currentWord.position[0].near, this.currentWord.position[0].near,this.currentWord);
                        return [this.currentWord];
                    }

                    return [this.currentWord,...this.currentWord.position[0].near];
                },

                wordsFilter ()
                {
                    if (this.filter === "")
                        return this.words;

                    let strRegex =`(${this.filter})`;
                    let regex = new RegExp(strRegex);

                    return this.words.filter( w => w.word.match(regex))
                },


                bestScore ()
                {
                    return this.wordsFilter.sort( (a,b) => b.bestScore - a.bestScore)
                        .slice(0, 25);
                },



                userLetters ()
                {
                    return this.holder.letters;
                }

            },


        methods:
            {



                ofLength (length)
                {
                    if (this.wordsFilter == null)
                        return [];


                    if (this.wordsFilter.filter == undefined)
                    {
                        console.log("wordsFilter", typeof this.wordsFilter );
                    }

                    return this.wordsFilter.filter( w => w.word.length == length)
                        .sort( (a,b) =>  a.word < b.word ? -1 : a.word > b.word ? 1: 0);
                },

                displayTab (tab)
                {
                    this.currentTab = tab;
                },


                shouldDisplay (tab)
                {
                    return (this.currentTab === tab);

                },

                bestScoreOf (length)
                {
                    let words = this.ofLength(length);

                    return words.reduce( (p,w) => p.bestScore > w.bestScore ? p : w).bestScore;
                },


                toggleShowWordOf (i)
                {

                    if (this.arrShowWordOfLength[i] == undefined)
                    {
                        this.arrShowWordOfLength[i] =  true;

                    }

                    //this.arrShowWordOfLength[i] = !this.arrShowWordOfLength[i];

                    Vue.set(this.arrShowWordOfLength, i, !this.arrShowWordOfLength[i]);

                } ,


                showWordOf (i)
                {

                    if (this.arrShowWordOfLength[i] == undefined)
                        return true;

                    return this.arrShowWordOfLength[i];
                },


                showPopover (e)
                {
                    let t = $(e.target);

                    if (this.popover != null)
                    {
                        t.popover('hide');
                    }


                    t.popover('show');

                    this.popover = t;
                },

                hidePopover (e)
                {
                    let t = $(e.target);
                    t.popover('hide');

                    this.popover = null;
                },

                wordClick (word, showNext)
                {
                    console.log("Result wordClick", word);
                    console.log("Result wordClick", arguments);

                    if (this.currentWord != null && this.currentWord.word === word.word)
                    {
                        showNext();
                    }

                    this.currentWord = word;

                    this.$emit("wordClick", word);

                    console.log("after emit");
                },

                useWord ()
                {
                    console.log("Result::useWord");
                    this.$emit("useCurrentWord");

                    this.currentWord =  null;
                },

                removeWord ()
                {
                    console.log("Result::removeWord");
                    this.$emit("removeCurrentWord");

                    this.currentWord =  null;
                },

                filterResults ()
                {
                    let filter = this.$refs.holderFilter.letters.join("");

                    filter = filter.trim().toLowerCase();
                    filter = filter.replace(/([a-z])/g,"($1)").replace(/(\s)/, "([a-z])");

                    this.filter = filter;
                    console.log("filterResults", filter);
                }
            },

        updated ()
        {
            if (this.bTabSelected )
                return;

            if (this.$refs["tab-best-score"] != undefined)
            {
                this.$refs["tab-best-score"].click();
                this.currentTab = "tab-best-score";
            }

            this.bTabSelected = true;
            /*let ref = "tab" + this.firstTabIndex.toString();

            if (this.firstTabIndex == 0)
                return;

            let tab = this.$refs[ref];

            if (tab != undefined)
            {
                tab[0].click();
                this.bTabSelected = true;
            }*/
        },

        watch:
            {
                words (newVal, oldVal)
                {
                    this.bTabSelected = false;
                    this.currentTab = "";

                    //this.$refs["tab" + this.firstTabIndex].click();
                }
            }


    }
</script>

<style scoped>
    .btn-nav
    {
        margin-left: 8px;
        background-color:#F3D79F;
        color:#000000;
    }

    .tab-best-score
    {
        color:#7A6C50;
        font-size: 9px;
    }

    .btn-word
    {
        background-color:#F3D79F;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
</style>