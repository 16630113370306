import Dictionnaries from "./Dictionnaries.js";




function onReady ()
{
    window.main = new Dictionnaries();
    
    
}

document.addEventListener('DOMContentLoaded', onReady);